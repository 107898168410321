import React from "react";
import { NavLink } from "react-router-dom";
export default function LandingHeader() {

    return (
        <>

            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink aria-current="page" className="navbar-brand" >
                                <img src="./img/logo_new.png" alt="logo" /></NavLink>
                            <button className="navbar-toggler navbar-toggler-right collapsed"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span></button>

                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">

                                    <li className="nav-item"><NavLink className="nav-link"
                                    >Contact <i
                                        className="bx bx-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"> <div className="option-item"><a className="default-btn"
                                                href="tel:+61731529575"><i
                                                    className="bx bx-phone"></i>Australia: +61731529575 <span></span></a></div></li>
                                            <li className="nav-item"><div className="option-item"><a className="default-btn"
                                                href="tel:+918295195516"><i
                                                    className="bx bx-phone"></i>India: +918295195516 <span></span></a></div></li>
                                            <li className="nav-item"><div className="option-item"><a className="default-btn"
                                                href="tel:+61731529575"><i
                                                    className="bx bx-phone"></i> 61731529575 <span></span></a></div></li>

                                        </ul>
                                    </li>


                                </ul>
                                <div className="others-option d-flex align-items-center">


                                    <div className="option-item"><a className="default-btn"
                                        href="tel:0423973511"><i
                                            className="bx bx-phone"></i> Call Now <span></span></a></div>

                                   
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}