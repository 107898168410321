import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../Path";

export default function AboutUs() {


    return (
        <>

            <div class="page-title-area">
                <div class="container">
                    <div class="page-title-content">
                        <h2>About</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>About Us</li>
                        </ul>
                    </div>
                </div>

                <div class="shape-img3">

                    <img src="./img/about.jpg"></img>
                </div>
                <div class="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div>
            </div>

            <section class="about-area ptb-100">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="about-image"><img
                                src="./img/Discussion.gif"
                                alt="banner" /></div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="about-content">
                                <div class="content"><span class="sub-title"><img
                                    src="./img/lodaer.png"
                                    alt="banner" />About Us</span>
                                    <h2>Aussie Immigration Consultancy</h2>
                                    <p>Aussie Immigration Consultancy is productive, respects your time, and provides results quickly. We understand the fear people face while thinking of the visa process. We offer the required assistance because obtaining a visa might be challenging. We support you throughout the whole visa application procedure, starting at the very beginning. The Department of Home Affairs in Australia requires various lengthy, intricate forms, which we compile and complete for you.</p>
                                    <ul class="features-list">
                                        <li><img src="./img/icon1.png"
                                            alt="banner" />
                                            <h3>10 Years</h3>
                                            <p>On the market</p>
                                        </li>
                                        <li><img src="./img/icon2.png"
                                            alt="banner" />
                                            <h3>45+</h3>
                                            <p>Team members</p>
                                        </li>
                                        <li><img src="./img/icon3.png"
                                            alt="banner" />
                                            <h3>100%</h3>
                                            <p>Satisfaction rate</p>
                                        </li>
                                        <li><img src="./img/icon4.png"
                                            alt="banner" />
                                            <h3>80%</h3>
                                            <p>Senior scientist</p>
                                        </li>
                                    </ul>
                                    <p>Our goal is to give you the proper paperwork for whatever sort of visa you could need to immigrate to Australia.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="circle-shape1"><img
                    src="./img/circle-shape1-01ae53caad7e9930c72205eddba707d3.png"
                    alt="banner" /></div>
                <div class="container">
                    <div class="about-inner-area">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="about-text">
                                    <h3>Our History</h3>
                                    <p>Real innovations and a positive customer experience are the heart of successful
                                        communication.</p>
                                    <ul class="features-list">
                                        <li><i class="flaticon-tick"></i> Activate Listening</li>
                                        <li><i class="flaticon-tick"></i> Brilliant minds</li>
                                        <li><i class="flaticon-tick"></i> Better, Best, Wow!</li>
                                        <li><i class="flaticon-tick"></i> Branding it better!</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6">
                                <div class="about-text">
                                    <h3>Our Mission</h3>
                                    <p>Our primary goal is continuously to provide immigration and visa services to our clients through our expertise and experience. Our offerings.</p>
                                    <ul class="features-list">
                                        <li><i class="flaticon-tick"></i> IELTS, PTE, Spoken English, etc. preparation.</li>
                                        <li><i class="flaticon-tick"></i> Legal visa Documentation.</li>
                                        <li><i class="flaticon-tick"></i> Uninterrupted Visa Service.</li>
                                        <li><i class="flaticon-tick"></i> Expert Counselor.</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 col-sm-6 offset-lg-0 offset-md-3 offset-sm-3">
                                <div class="about-text">
                                    <h3>Who we are</h3>
                                    <p>We are a group of skilled visa specialists that are aware of every procedure needed to obtain your visa.</p>
                                    <ul class="features-list">
                                        <li><i class="flaticon-tick"></i> Ease your visa process.</li>
                                        <li><i class="flaticon-tick"></i> Respect your time.</li>
                                        <li><i class="flaticon-tick"></i> Follow all legal procedures.</li>
                                        <li><i class="flaticon-tick"></i> Supportive experts.</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="circle-shape1"><img
                    src="./img/circle-shape1-01ae53caad7e9930c72205eddba707d3.png"
                    alt="banner" /></div>
            </section>
        </>
    );
}