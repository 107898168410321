import visaimage from "../Images/visa-img.gif";
import eduimg from "../Images/edu-1.png"
import service from "../Images/service1.gif"
import service1 from "../Images/services.png"
import team1 from "../Images/team1-9f4b3d1c105e4219d0cb9dfa4a14e64f.jpg"
import team2 from "../Images/team2-310f2109ea4b6ddb7463151bf6c1b6f4.jpg"
import team3 from "../Images/team4-80769bbee28e23512c30bf979232f286.jpg"
import team4 from "../Images/team4-80769bbee28e23512c30bf979232f286.jpg"
import project1 from "../Images/project1-134611850b85bce10566364386e74756.jpg"
import project2 from "../Images/project2-72d0d71900dee6c1c3b754157a9708a0.jpg"
import project3 from "../Images/project3-31ce152f2ab26663d4541f878cb95892.jpg"
import project4 from "../Images/project4-3ecace795aba3cfa96d182e04fc1ca04.jpg"
import project5 from "../Images/project5-95cc3df92fe29e6bcb473e0b2596b855.jpg"
import project6 from "../Images/project6-a413d5b3e3f34bd8d9c0ce815d471490.jpg"
import blog1 from "../Images/blog-img1-32f134179c6698c2f899b3dae0f100b4.jpg"
import blog2 from "../Images/blog-img6-c4849c23f0f96fb9db3bb5a803625efa.jpg"
import blog3 from "../Images/blog-img5-a1121781a0417e0c2f1432ba1238655d.jpg"
import blog4 from "../Images/blog-img6-c4849c23f0f96fb9db3bb5a803625efa.jpg"
import blog5 from "../Images/blog-img6-c4849c23f0f96fb9db3bb5a803625efa.jpg"
import blog6 from "../Images/blog-img6-c4849c23f0f96fb9db3bb5a803625efa.jpg"
import circle from "../Images/circle-shape1-01ae53caad7e9930c72205eddba707d3.png"
import start from "../Images/project-start1-f0146a349ec9af6e82774320598c8f78.png"
import projstart from "../Images/project-start1-f0146a349ec9af6e82774320598c8f78.png"
import start1 from "../Images/start1.gif"
import start2 from "../Images/start2.gif"

export const Image =[
    {
        id : 1,
        path : visaimage,
    },
    {
        id: 2,
        path : eduimg,
    },
    {
        id:3,
        path : service,
    },
    {
        id:4,
        path : service1,
    },
    {
        id:5,
        path : team1,
    },
    {
        id:5,
        path : team2,
    },
    {
        id:6,
        path : team3,
    },
    {
        id:7,
        path : team4,
    },
    {
        id:8,
        path: project1,
    },
    {
        id:9,
        path: project2,
    },
    {
        id:10,
        path: project3,
    },
    {
        id:11,
        path: project4,
    },
    {
        id:12,
        path: project5,
    },
    {
        id:13,
        path: project6,
    },
    {
        id:14,
        path: blog1,
    },
    {
        id:15,
        path: blog2,
    },
    {
        id:16,
        path: blog3,
    },
    {
        id:17,
        path: blog4,
    },
    {
        id:18,
        path: blog5,
    },
    {
        id:19,
        path: blog6,
    },
    {
        id:20,
        path: circle,
    },
    {
        id:21,
        path: start,
    },
    {
        id:22,
        path: projstart,
    },
    {
        id:23,
        path: start1,
    },
    {
        id:24,
        path: start2,
    }
]