

import Footer from "./component/Footer";
import Header from "./component/Header";
import LandingHeader from "./component/LandingHeader";
import RouterComponent from "./component/RouterComponent";

export default function App() {
  let urldata = window.location.pathname;

  return <>
  {urldata === "/landing-page" ? 
   <div id="___gatsby">
   <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
     <div></div>
     <LandingHeader/>
     <RouterComponent />
   </div>
 </div>
  : 
   <div id="___gatsby">
   <div className="main_outline" tabindex="-1" id="gatsby-focus-wrapper">
     <div></div>
     <Header />
     <RouterComponent />
     <Footer />
   </div>
 </div>
  }
   
  </>;
}