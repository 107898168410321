import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "./Path";
export default function Header() {

    return (
        <>

            <div id="navbar" className="navbar-area">
                <div className="tarn-nav">
                    <div className="container-fluid">
                        <nav className="navbar navbar-expand-lg navbar-light">
                            <NavLink aria-current="page" className="navbar-brand"
                                to={SITE_PATHS.LOGOCLICK}>
                                <img src="./img/logo_new.png" alt="logo" /></NavLink>
                            <button className="navbar-toggler navbar-toggler-right collapsed"
                                type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
                                aria-controls="navbarSupportedContent" aria-expanded="false"
                                aria-label="Toggle navigation">
                                <span className="icon-bar top-bar"></span>
                                <span className="icon-bar middle-bar"></span>
                                <span className="icon-bar bottom-bar"></span></button>
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                <ul className="navbar-nav">
                                    <li className="nav-item"><NavLink aria-current="page" className="nav-link "
                                        to={SITE_PATHS.DASHBOARD}>Aussie <i
                                            className="bx bx-chevron-down"></i></NavLink>

                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.ABOUT}>About Us</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.HISTORY}>History</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.TESTIMONIALS}>Testimonials</NavLink>
                                            </li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.FAQ}>FAQ</NavLink>
                                            </li>

                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.GALLARY}>Gallery</NavLink>
                                            </li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.TEAM}>Our Team</NavLink>
                                            </li>
                                        </ul>
                                    </li>

                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.SERVICE}>Services <i
                                            className="bx bx-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.IELTS}>IELTS</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.PTE}>PTE
                                                Details</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.SPOKENENGLISH}>Spoken English
                                                Details</NavLink></li>

                                        </ul>
                                    </li>

                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.AUSTRALIA}>Study Abroad <i
                                            className="bx bx-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.AUSTRALIA}>Study Visa In AUSTRALIA</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.CANADA}>Study Visa In CANADA</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.UK}>Study Visa In UK</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.USA}>Study Visa In USA</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.NEWZEALAND}>Study Visa In New Zealand</NavLink></li>
                                        </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.COURSE}>Course<i
                                            className="bx bx-chevron-down"></i></NavLink>
                                        <ul className="dropdown-menu">
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE}>Course</NavLink></li>
                                            <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE1}>Student Visa </NavLink></li> 
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE2}>Permanent Residence </NavLink></li> 
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE3}>Tourist Visa </NavLink></li> 
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE4}>Spouse/Family Visa</NavLink></li> 
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE5}>Parent Visa </NavLink></li> 
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE6}>Work Visa </NavLink></li> 
                                                 {/* <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE7}>Course Details</NavLink></li>  */}
                                                 <li className="nav-item"><NavLink className="nav-link"
                                                to={SITE_PATHS.COURSE8}>Education Visa </NavLink></li> 
                                                  </ul>
                                    </li>
                                    <li className="nav-item"><NavLink className="nav-link"
                                        to={SITE_PATHS.CONTACT}>Contact Us <i
                                            className="bx bx-chevron-down"></i></NavLink>

                                    </li>

                                </ul>
                                <div className="others-option d-flex align-items-center">


                                    <div className="option-item"><a className="default-btn"
                                        href="tel:0423973511"><i
                                            className="bx bx-phone"></i> Call Now <span></span></a></div>

                                    <div className="option-item"><NavLink className="default-btn"
                                        to={SITE_PATHS.CONTACT}><i
                                            className="bx bx-right-arrow-alt"></i> Get Started <span></span></NavLink></div>
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </>
    );
}