import React from "react";
import { Routes, Route } from "react-router-dom";
import AboutUs from "./page/AboutUs";
import ContactUs from "./page/ContactUs";
import Blog1 from "./page/blogs/Blog1";
import Blog2 from "./page/blogs/Blog2";
import Blog3 from "./page/blogs/Blog3";
import Course1 from "./page/course/Course1";
import Course2 from "./page/course/Course2";
import Course3 from "./page/course/Course3";
import Course4 from "./page/course/Course4";
import Course5 from "./page/course/Course5";
import Course6 from "./page/course/Course6";
import Course7 from "./page/course/Course7";
import Course8 from "./page/course/Course8";
import Courses from "./page/Courses";
import CoursesWithDetails from "./page/CoursesWithDetails";
import Dashboard from "./page/Dashboard";
import Disclaimer from "./page/Disclaimer";
import FAQ from "./page/FAQ";
import Gallary from "./page/Gallary";
import History from "./page/History";
import IELTS from "./page/IELTS";
import OurTeam from "./page/OurTeam";
import PrivacyPolicy from "./page/PrivacyPolicy";
import PTE from "./page/PTE";
import Services from "./page/Services";
import ServicesWithDetails from "./page/ServicesWithDetails";
import SpokenEnglish from "./page/SpokenEnglish";
import Study_Visa_In_Australia from "./page/Study_Visa_In_Australia";
import Study_Visa_In_Canada from "./page/Study_Visa_In_Canada";
import Study_Visa_In_NewZealand from "./page/Study_Visa_In_NewZealand";
import Study_Visa_In_UK from "./page/Study_Visa_In_UK";
import Study_Visa_In_USA from "./page/Study_Visa_In_USA";
import TermsOfService from "./page/TermsOfService";
import Testimonials from "./page/Testimonials";
import  LandingPage from "./LandingPage";
export default function RouterComponent() {
  return (
    <>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/" element={<Dashboard />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/course" element={<Courses />} />
        <Route path="/course-details" element={<CoursesWithDetails />} />
        <Route path="/faq" element={<FAQ />} />
        <Route path="/gallary" element={<Gallary />} />
        <Route path="/history" element={<History />} />
        <Route path="/ielts" element={<IELTS />} />
        <Route path="/our-policy" element={<PrivacyPolicy />} />
        <Route path="/pte" element={<PTE />} />
        <Route path="/service" element={<Services />} />
        <Route path="/service-details" element={<ServicesWithDetails />} />
        <Route path="/spoken-english" element={<SpokenEnglish />} />
        <Route path="/Study-Visa-In-Australia" element={<Study_Visa_In_Australia />} />
        <Route path="/Study-Visa-In-Canada" element={<Study_Visa_In_Canada />} />
        <Route path="/Study-Visa-In-UK" element={<Study_Visa_In_UK />} />
        <Route path="/Study-Visa-In-USA" element={<Study_Visa_In_USA />} />
        <Route path="/Study-Visa-In-NewZealand" element={<Study_Visa_In_NewZealand />} />
        <Route path="/Terms-Of-Service" element={<TermsOfService />} />
        <Route path="/Testimonials" element={<Testimonials />} />
        <Route path="/team" element={<OurTeam />} />
        <Route path="/disclaimer" element={<Disclaimer />} />
        <Route path="/Education-System-in-Australia" element={<Blog1 />} />
        <Route path="/Entry-Requirements-Australian-Universities" element={<Blog2 />} />
        <Route path="/Visa-Assistance" element={<Blog3 />} />
        <Route path="/Student-Visa" element={<Course1 />} />
        <Route path="/Permanent-Residence" element={<Course2 />} />
        <Route path="/Tourist-Visa" element={<Course3 />} />
        <Route path="/Family-Visa" element={<Course4 />} />
        <Route path="/Parent-Visa" element={<Course5 />} />
        <Route path="/Work-Visa" element={<Course6 />} />
        <Route path="/Work-Visa2" element={<Course7 />} />
        <Route path="/Education-Visa" element={<Course8 />} />
        <Route path="/landing-page" element={<LandingPage />} />
      </Routes>
    </>
  );
}
