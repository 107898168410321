import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "../../Path";

export default function Blog3() {


    return (
        <>

            <div class="page-title-area">
                <div class="container">
                    <div class="page-title-content">
                        <h2>Student Visa Assistance for Australia</h2>
                        <ul>
                            <li><NavLink to={SITE_PATHS.LOGOCLICK}>Home</NavLink></li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </div>

                <div class="shape-img3">

                    <img src="./img/about.jpg"></img>
                </div>
                <div class="shape-img3">
                    <img
                        src=""
                        alt="banner" />
                </div>
            </div>
        </>
    );
}