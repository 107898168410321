import React from "react";
import { NavLink } from "react-router-dom";
import SITE_PATHS from "./Path";

export default function Footer() {

    return (
        <>

            <footer className="footer-area bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-footer-widget"><NavLink to={SITE_PATHS.LOGOCLICK}
                                className="logo"><img
                                    src="./img/logo_new.png"
                                    alt="logo" /></NavLink>
                                <p>We provide expert migration services through our experienced migration agents. Any concerns relating to visas are handled with the finest professional advice. We offer to coach non-native English speakers as well.</p>
                                <ul className="social-link">
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i
                                            className="bx bxl-facebook"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i
                                            className="bx bxl-twitter"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i
                                            className="bx bxl-instagram"></i></NavLink></li>
                                    <li><NavLink className="d-block" target="_blank" rel="noreferrer"
                                        to={SITE_PATHS.CONTACT}><i
                                            className="bx bxl-linkedin"></i></NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="single-footer-widget pl-5">
                                <h3>Explore</h3>
                                <ul className="footer-links-list">
                                    <li><NavLink to={SITE_PATHS.DASHBOARD}>Home</NavLink></li>
                                    <li><NavLink to={SITE_PATHS.ABOUT}>About</NavLink></li>

                                    <li><NavLink to={SITE_PATHS.HISTORY}>Our Blog</NavLink></li>
                                    <li><NavLink aria-current="page" className=""
                                        to={SITE_PATHS.CONTACT}>Contact</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-2 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Resources</h3>
                                <ul className="footer-links-list">
                                    <li><NavLink href="#">Our Scientists</NavLink></li>
                                    <li><NavLink to={SITE_PATHS.SERVICE}>Our Services</NavLink></li>
                                    <li><NavLink to={SITE_PATHS.TESTIMONIALS}>Testimonials</NavLink></li>

                                    <li><NavLink href="#">Case Studies</NavLink></li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-4 col-sm-6">
                            <div className="single-footer-widget">
                                <h3>Address</h3>
                                <ul className="footer-contact-info">
                                    <li><i className="bx bx-map"></i>Brisbane QLD <br /> Australia
                                    </li>
                                    <li><i className="bx bx-phone-call"></i><a href="tel:0423973511">+61-423973511</a>
                                    </li>
                                    <li><i className="bx bx-envelope"></i><a href="mailto:info@aussieimmigration.com.au">info@aussieimmigration.com.au</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="footer-bottom-area">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-6">
                                <p>@2022 | All rights reserved by <a href="" rel="noreferrer">aussieimmigration.com.au</a></p>
                            </div>
                            <div className="col-lg-6 col-md-6">
                                <ul>
                                    <li><NavLink to={SITE_PATHS.POLICY}>Privacy Policy</NavLink>
                                    </li>
                                    <li><NavLink to={SITE_PATHS.TERMSOFSERVICE}>Terms &amp;
                                        Conditions</NavLink></li>

                                    <li><NavLink to={SITE_PATHS.DISCLAIMER}>disclaimer </NavLink></li>                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-map"><img
                    src="./img/footer-map-80b0cb4fab6933f0cf7d485070e2fdd5.png"
                    alt="footer-logo" /></div>
            </footer>
        </>
    );
}